<template>
  <div class="our-team-wrapper dark-bg" id="team">
    <div class="container">
      <h4>MEET OUR TEAM</h4>
      <h2 class="heading">
        Our Experts are ready to help grow your business
        <span class="trilon-pink heading-dot">.</span>
      </h2>

      <p class="subheading">
        We've worked with small start-ups, to Fortune 500 companies, leading
        them all to success.
      </p>

      <div class="team-wrapper-row row">
        <div
          v-for="(member, key) in highlightedMembers"
          :key="member.name"
          v-bind:class="{ 'offset-xl-1': key === 0 }"
          class="trilon-highlighted-member col-xs-12 col-lg-6 col-xl-5"
        >
          <div
            class="member-thumbnail cursor-pointer"
            @click="showDetails(key)"
          >
            <!-- there must be a way to load these without doing stupid require() -->
            <!-- <img :src="'/_nuxt/assets/photos/' + member.thumbnail" v-bind:alt="member.name + ' | Trilon'"> -->
            <img
              :src="member.thumbnail"
              v-bind:alt="member.name + ' | Trilon'"
            />
          </div>
          <div class="member-info-container">
            <div class="member-title">
              <span v-if="member.twitter" class="twitter-handle trilon-pink">
                <a
                  :href="`https://twitter.com/${member.twitter}`"
                  class="trilon-pink"
                  target="_blank"
                  rel="noopener"
                  >@{{ member.twitter }}</a
                >
              </span>
              <span
                v-if="member.external_link"
                class="twitter-handle trilon-pink"
              >
                <a
                  :href="member.external_link"
                  class="trilon-pink"
                  target="_blank"
                  rel="noopener"
                  >@{{ member.external_link_name }}</a
                >
              </span>
              <h2 @click="showDetails(key)" class="founder cursor-pointer">
                {{ member.name }}
              </h2>
              <span class="gray">{{ member.title }}</span>
            </div>

            <p class="gray member-description" v-html="member.description"></p>
          </div>
          <!--<br />
          <br />
          <div class="read-more" @click="showDetails(key)">
            <span class="gray">more</span>
            <arrow />
          </div>-->
        </div>
      </div>
      <TeamMember
        class="member-details"
        :member="currentMember"
        :homepage="!showAllMembers"
        v-bind:class="{ collapsed: isCollapsed }"
        v-bind:close="close"
      />
      <div class="row">
        <div
          v-for="(member, key) in teamMembers"
          :key="member.name"
          v-bind:class="{ 'offset-xl-1': key % 5 === 0 }"
          class="trilon-member col-sm-6 col-md-3 col-xl-2"
        >
          <div class="trilon-member-thumb">
            <img
              :src="member.thumbnail"
              v-bind:alt="member.name + ' | Trilon'"
            />
          </div>
          <div class="trilon-member-info">
            <h5>{{ member.name }}</h5>
            <h6>{{ member.title }}</h6>
          </div>
        </div>
        <div class="trilon-member col-sm-6 col-md-3 col-xl-2">
          <div class="trilon-member-thumb">
            <img
              src="/assets/photos-v2/placeholder.png"
              alt="Placeholder | Trilon"
            />
          </div>
          <div class="trilon-member-info">
            <h5>You</h5>
            <h6><a href="/careers" class="apply-now">Apply now</a></h6>
          </div>
        </div>
      </div>
    </div>

    <ViewTeam></ViewTeam>

    <img src="~/assets/bg-upper-wave.png" class="wave-lower-large" />
  </div>
</template>

<script>
import TeamMember from '~/components/TeamMember.vue';
import Arrow from '~/components/Arrow.vue';
import ViewTeam from '~/components/ViewTeam.vue';
// console.log(useNuxtApp())
export default {
  components: {
    TeamMember,
    Arrow,
    ViewTeam,
  },
  props: {
    hideTeamHeading: Boolean,
    showAllMembers: Boolean,
  },
  data: () => ({
    activeId: -1,
    isCollapsed: true,
  }),
  computed: {
    currentMember: function () {
      return this.activeId < 0 ? {} : this.highlightedMembers[this.activeId];
    },
    highlightedMembers: function () {
      if (this.showAllMembers) {
        return this.$store.teamMembers;
      }

      // Homepage
      return this.$store.teamMembers
        .filter((member) => member.highlight)
        .map((member) => {
          return {
            ...member,
            videos: member.videos.filter((video) => video.homepage !== false),
          };
        });

      // Previous code
      // const talks = this.$store.talks;
      // return this.$data.items.map(member => {
      //   member.videos = talks.filter(
      //     talk => talk.name === member.name && !talk.isThumbnailHidden
      //   );
      //   return member;
      // });
    },
    teamMembers: function () {
      return this.$store.teamMembers.filter((member) => !member.highlight);
    },
    isScrollNeeded: function () {
      return typeof window !== 'undefined' && window.innerHeight < 1000;
    },
  },
  methods: {
    close: function () {
      this.isCollapsed = true;
      this.$scrollTo('#team', 500, { offset: 0 });
    },
    showDetails: function (index, isHover) {
      // Prevent hover scenario on homepage ?
      // if (isHover && !this.showAllMembers) {
      //   return;
      // }

      // Homepage normal scenario
      this.isCollapsed = true;
      this.activeId = index;
      this.isCollapsed = false;

      // Team page "hover" scenario
      // if (isHover && this.showAllMembers) {
      //   return;
      // }

      if (isHover) {
        return;
      }

      // [todo] better way of doing this - just trying to not have the wrong image show up
      // Are props slow in Vue ??
      setTimeout(() => {
        let offset = -15;

        const elementSelector = '#member-profile';
        const elementHeight =
          document.querySelector(elementSelector).clientHeight;
        const windowHeight = window.innerHeight;
        if (windowHeight > elementHeight) {
          offset = -((windowHeight - elementHeight) / 2);
        }
        this.$scrollTo(elementSelector, 500, { offset });
        // Maybe only scroll for Mobile users?
        if (this.isScrollNeeded) {
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.our-team-wrapper {
  // padding: 5rem 1rem 0;

  $min-width: 1520;
  @for $i from 0 through 4 {
    @media (min-width: #{$min-width + $i * 100}px) {
      margin-bottom: #{$i + 1}rem;
    }
  }

  @media (min-width: 1201px) and (max-width: 1500px) {
    margin-bottom: 14rem;
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    margin-bottom: 4rem;
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #a5a6a9;
  }

  .apply-now {
    text-decoration: underline;
  }

  .heading {
    max-width: 680px;
    display: inline-block;
    margin-bottom: 30px;
  }

  .team-wrapper-row {
    margin-top: 5.5rem;
  }

  .trilon-highlighted-member {
    margin-bottom: 3rem;
    text-align: left;
    padding-left: 2rem;

    @media (max-width: 991px) {
      text-align: center;
      padding-left: 15px;
    }
  }

  .twitter-handle {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    display: block;

    a {
      transition: all 100ms ease-in-out;
    }
  }

  .member-title {
    padding-bottom: 25px;
  }

  .trilon-member {
    padding-top: 60px;
    text-align: center;

    @media (max-width: 575px) {
      display: none;
    }
  }

  .trilon-member-thumb {
    width: 88px;
    display: inline-block;
    margin: 0 auto 20px;

    img {
      border: 2px solid #372d52;
      border-radius: 50%;
    }
  }

  .trilon-member-info h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .trilon-member-info h6 {
    font-weight: 400;
    font-size: 14px;
    color: #a5a6a9;
  }

  .trilon-highlighted-member {
    .member-thumbnail {
      margin: 0 auto;
      width: 145px;
      // height: 145px;
      display: inline-block;
      vertical-align: top;
      transition: all 150ms ease-in;
      overflow: hidden;

      img {
        width: 100%;
        transition: all 150ms ease-in;
        border: 2px solid #372d52;
        border-radius: 50%;
      }

      @media (max-width: 480px) {
        margin-bottom: 20px;
        display: block;
      }
    }

    .member-info-container {
      display: inline-block;
      vertical-align: top;
      text-align: left;
      padding-left: 40px;

      @media (max-width: 480px) {
        text-align: center;
        padding: 0;
      }
    }

    &:hover {
      .member-thumbnail {
      }

      .read-more {
        cursor: pointer;

        span {
          color: #e91e63; // [todo] use vars
        }
        svg path {
          fill: #e91e63; // [todo] use vars
        }
      }
    }
  }

  .member-description {
    max-width: 250px;
    margin: 0 auto;

    a {
      text-decoration: underline;
    }
  }

  .founder {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .read-more {
    * {
      transition: all 150ms ease-in-out;
    }
    svg {
      transform: rotate(90deg);
      width: 20px;
    }
    svg path {
      fill: #717173; // [todo] use vars
    }

    &:hover {
      cursor: pointer;

      span {
        color: #e91e63; // [todo] use vars
      }
      svg path {
        fill: #e91e63; // [todo] use vars
      }
    }
  }

  .member-details {
    transition: all 350ms ease-in-out, opacity 350ms 400ms ease,
      transform 350ms 400ms ease;
    // max-height: 500px;
  }

  .collapsed {
    transition: all 350ms ease-in-out;
    transform: translateY(-1.5rem);
    max-height: 0;
    overflow: hidden;
    opacity: 0;
  }

  .wave-lower-large {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    transform: rotate(180deg);

    @media (min-width: 1501px) {
      display: block;
    }
  }
}
</style>
