<template>
  <div id="member-profile" class="member-wrapper">
    <div class="gradient-wrapper">
      <span class="close-icon" @click="close()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 212.982 212.982"
          style="enable-background: new 0 0 212.982 212.982"
          xml:space="preserve"
        >
          <g id="Close">
            <path
              style="fill-rule: evenodd; clip-rule: evenodd"
              d="M131.804,106.491l75.936-75.936c6.99-6.99,6.99-18.323,0-25.312   c-6.99-6.99-18.322-6.99-25.312,0l-75.937,75.937L30.554,5.242c-6.99-6.99-18.322-6.99-25.312,0c-6.989,6.99-6.989,18.323,0,25.312   l75.937,75.936L5.242,182.427c-6.989,6.99-6.989,18.323,0,25.312c6.99,6.99,18.322,6.99,25.312,0l75.937-75.937l75.937,75.937   c6.989,6.99,18.322,6.99,25.312,0c6.99-6.99,6.99-18.322,0-25.312L131.804,106.491z"
              fill="#FFFFFF"
            />
          </g>
        </svg>
      </span>
      <div class="row">
        <div
          class="d-sm-none d-md-block col-xs-12 col-md-3 col-lg-4 member-photo"
        >
          <!-- there must be a way to load these without doing stupid require() -->
          <!-- <img :src="'/_nuxt/assets/photos/' + member.photo" :alt="member.name + ' | Trilon Consulting'"> -->
          <img
            :src="member.photoLarge"
            :alt="member.name + ' | Trilon Consulting'"
          />
          <!-- <img :src="'/_nuxt/assets/photos/' + item.photo" :alt="item.name + ' | Trilon Consulting'"> -->
          <!-- <img :src="item.photo" :alt="item.name + ' | Trilon Consulting'" /> -->
        </div>
        <div class="col-sm-12 col-md-9 col-lg-8 member-info">
          <a
            v-if="member.twitter"
            :href="'https://www.twitter.com/' + member.twitter"
            rel="noopener"
            target="_blank"
            class="trilon-pink"
            >@{{ member.twitter }}</a
          >

          <a
            v-if="!member.twitter && member.social && member.social.length"
            :href="member.social[0].link"
            target="_blank"
            rel="noopener"
            >@{{ member.social[0].name }}</a
          >

          <h3>{{ member.name }}</h3>

          <div class="member-socials">
            <a
              v-if="member.twitter"
              :href="'https://twitter.com/' + member.twitter"
              target="_blank"
            >
              <span class="fab fa-twitter"></span>
            </a>
            <a
              v-if="member.github"
              :href="'https://github.com/' + member.github"
              target="_blank"
            >
              <span class="fab fa-github"></span>
            </a>
            <!-- v-if="member.social" -->
            <a
              v-for="s in member.social"
              :key="s.name"
              :href="s.link"
              target="_blank"
            >
              <span :class="'fab ' + s.icon"></span>
            </a>
          </div>

          <p v-html="member.bio"></p>
          <div class="videos-wrapper row align-items-center">
            <div
              v-for="(image, key) in member.images"
              :key="key"
              class="video col-sm-6"
            >
              <!-- <i class="fas fa-play"></i> -->
              <img :src="image" />
            </div>
            <div
              v-for="video in member.videos"
              :key="video.id"
              class="video col-sm-6 cursor-pointer"
            >
              <i class="fas fa-play"></i>
              <img @click="changeTalk(video.id)" :src="video.thumbnail" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    homepage: Boolean,
    close: Function,
    member: Object,
  },
  methods: {
    changeTalk(index) {
      this.$store.commit('changeTalk', index);

      this.$scrollTo('#trilon-conference-talks', 500, {
        offset: this.homepage
          ? 140 //  homepage offset needed
          : 25, // team page offset needed
      });
    },
  },
};
</script>

<style lang="scss">
.member-wrapper {
  text-align: left;
  max-width: 1000px; // [todo] cleanup
  margin: 0 auto;
  position: relative;

  .close-icon {
    position: absolute;
    width: 18px;
    height: 18px;
    z-index: 10;
    right: 40px;
    top: 35px;

    svg path {
      transition: all 80ms ease-in-out;
      fill: #e91e63; // [todo] use vars
    }

    &:hover {
      cursor: pointer;
      svg path {
        fill: #fff;
      }
    }
  }

  .videos-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;

    .video {
      position: relative;

      &:hover {
        img {
          border-bottom: 10px #e91e63 solid;
        }
        i {
          opacity: 1;
        }
      }

      i {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;
        font-size: 1.4rem;
      }

      img {
        height: auto;
        width: 100%;
        // max-width: 350px;
        margin-bottom: 2rem;
        border-bottom: 10px transparent solid;
      }
    }
  }
}

.gradient-wrapper {
  padding: 5px 20px;

  .row {
    background: #070a19; // [todo] use vars
  }
}

.member-photo {
  padding: 0;
  overflow: hidden;

  img {
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

.member-info {
  padding: 1.5rem 2rem 0 2rem;

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin: 0.5rem 0 0.5rem;
  }

  p {
    font-size: 0.95rem;
    line-height: 1.6rem;
    color: #edeef0; // [todo] use vars
  }

  .trilon-pink {
    font-weight: 500;
  }
}

.member-socials {
  margin: 0.5rem 0;

  a {
    color: #fff;
    margin: 0 0.2rem;

    &:hover {
      color: #e91e63;
    }
  }
}
</style>
