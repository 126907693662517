<template>
  <div>
    <header class="intro">
      <div class="container">
        <Navigation :homepage="true" :currentTab="'home'" />

        <h1 class="trilon-title">
          We are Trilon
          <span class="trilon-pink">.</span>
        </h1>

        <h2 class="trilon-slogan">
          Open Source Fanatics &
          <br />Key Contributors
        </h2>

        <br />
        <a href="#" v-scroll-to="'#services'" class="btn btn-primary"
          >Check our Services</a
        >
        <a href="#" v-scroll-to="'#team'" class="btn btn-link"
          >More about Trilon</a
        >
        <br />
        <div class="scroll-down-wrapper">
          <img src="~/assets/icons/mouse.svg" class="icon-mouse" />
          <img src="~/assets/icons/arrow-long.svg" class="arrow-long" />
        </div>
      </div>
    </header>

    <div class="wave-wrapper">
      <WhatWeDo></WhatWeDo>
      <OurTeam></OurTeam>
    </div>

    <div class="container our-talks-wrapper">
      <CountriesVisited></CountriesVisited>
      <OurTalks :homepage="true"></OurTalks>
    </div>

    <WhoWeWorkWith></WhoWeWorkWith>

    <ContactUs></ContactUs>
  </div>
</template>

<script setup>
if (typeof document !== 'undefined') {
  document.addEventListener('DOMContentLoaded', (ev) => {
    // console.log(window.location.hash);

    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        this.$scrollTo(hash, 500, { offset: -25 });
      }, 200);
    }
  });
}

const structuredData = ref({
  '@context': 'https://schema.org',
  '@graph': [
  {
    '@type': 'Organization',
    '@id': 'https://trilon.io/#organization',
    name: 'Trilon',
    url: 'https://trilon.io/',
    sameAs: [],
    logo: {
      '@type': 'ImageObject',
      '@id': 'https://trilon.io/#logo',
      url: 'https://trilon.io/trilon-logo-clear.png',
      caption: 'Trilon',
    },
    image: {
      '@id': 'https://trilon.io/#logo',
    },
  },
  {
    '@type': 'WebSite',
    '@id': 'https://trilon.io/#website',
    url: 'https://trilon.io/',
    name: 'Trilon',
    publisher: {
      '@id': 'https://trilon.io/#organization',
    },
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://trilon.io/?search={search_term_string}',
      'query-input': 'required name=search_term_string',
    },
  },
  {
    '@type': 'WebPage',
    '@id': 'https://trilon.io/blog#webpage',
    url: 'https://trilon.io/blog',
    inLanguage: 'en-US',
    name: 'Next-level consulting from NestJS and Angular Universal core team members.',
    isPartOf: {
      '@id': 'https://trilon.io/#website',
    },
    about: {
      '@id': 'https://trilon.io/#organization',
    },
    image: {
      '@type': 'ImageObject',
      '@id': 'https://trilon.io/#primaryimage',
      url: 'https://trilon.io/meta/og-image.png',
      caption: 'Trilon',
    },
    primaryImageOfPage: {
      '@id': 'https://trilon.io/#primaryimage',
    },
    datePublished: '2019-02-24T10:30:12+00:00',
    dateModified: '2019-07-17T11:28:59.031Z',
    description:
      'Next-level consulting from NestJS and Angular Universal core team members. We give teams the push they need to truly succeed in today&#x27;s ever-changing world.',
  },
  ]
});
useHead({
  
      title: 'Trilon - Next-level Application Consulting',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          vmid: 'description',
          name: 'description',
          content:
            "Next-level consulting from NestJS and Angular Universal core team members. We give teams the push they need to truly succeed in today's ever-changing world.",
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          innerHTML: JSON.stringify(structuredData),
          type: 'application/ld+json',
        },
      ],
})
</script>

<style lang="scss">
.scroll-down-wrapper {
  position: relative;

  .icon-mouse,
  .arrow-long {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .icon-mouse {
    width: 28px;
    top: 4rem;
    animation: moveTop 2s forwards infinite;
  }

  @keyframes moveTop {
    0% {
      transform: translateY(0) translateX(-50%);
    }
    25% {
      transform: translateY(0) translateX(-50%);
    }
    50% {
      transform: translateY(-6px) translateX(-50%);
    }
    100% {
      transform: translateY(0) translateX(-50%);
    }
  }

  .arrow-long {
    top: 6.8rem;
  }
}
</style>
