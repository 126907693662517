<template>
  <div class="view-team-wrapper">
    <a href="/careers" class="btn btn-secondary mt-5">Want to join our team?</a>
    <a href="/team" class="btn btn-primary mt-5">Get to know us!</a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.view-team-wrapper {
  padding: 2rem 0;
  text-align: center;

  .btn {
    margin: 0 10px;

    @media (max-width: 767px) {
      display: block;
      width: 300px;
      max-width: 95%;
      margin: 0 auto;
      margin-top: 20px !important;
    }
  }
}
</style>
